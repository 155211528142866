var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Create a widget of tabbable panes of local content. The tabs component is built upon navs and cards internally, and provides full keyboard navigation control of the tabs. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('pills')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('fill')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('justified')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('alignment')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('vertical-tabs')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('external-controls')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('dynamic-tabs')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }